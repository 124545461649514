import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import Confetti from "react-confetti";
import { routes } from "../constants/routes";
import { toast } from "react-toastify";
import axios from "axios";
import { Endpoints } from "../constants/Endpoints";

const BookingConfirmation = () => {
  const [searchParams] = useSearchParams();
  const order_id = searchParams.get("id");

  const navigate = useNavigate();

  const [showSuccess, setShowSuccess] = useState(true);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [bookingDetails, setBookingDetails] = useState({
    orderId: "unknown",
    totalAmount: "0",
    discount: "0",
    balance: "0",
    orderDetails: [],
  });

  const fetchBookingDetails = async (order_id) => {
    try {
      const response = await axios.get(
        `${Endpoints.API_URL}/orders/success-order?order_id=${order_id}`
      );
      if (response.status === 200) {
        const data = response.data;
        setBookingDetails({
          orderId: data.order.order_id,
          totalAmount: data.order.order_total,
          discount: data.order.discounted_amount,
          balance: data.order.pending_fees,
          orderDetails: data.orderDetails,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Can't able to fetch Order details");
    }
  };

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      navigate(`${routes.MY_COURSES}`);
    }, 5000);
    return () => clearTimeout(redirectTimer);
  }, [navigate]);

  useEffect(() => {
    const timer = setTimeout(() => setShowSuccess(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchBookingDetails(order_id);
  }, [order_id]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4 relative">
      <Confetti
        width={windowDimensions.width}
        height={windowDimensions.height}
        numberOfPieces={100}
      />

      {showSuccess && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white rounded-lg px-4 py-2 shadow-lg flex items-center space-x-2 animate-slide-down">
          <FaCheckCircle className="text-xl" />
          <span>Booking Confirmed Successfully!</span>
        </div>
      )}

      <div className="max-w-4xl w-full bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-bold text-center text-blue-600">
          Thank You for Your Booking!
        </h1>
        <p className="mt-2 text-center text-gray-700">
          Your booking has been successfully completed.
        </p>

        <div className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Booking Information:
          </h2>
          <div className="mt-4 bg-gray-50 rounded-lg p-4 border border-gray-200">
            <div className="grid grid-cols-2 gap-4 text-gray-700">
              <div className="font-medium">Order ID:</div>
              <div>{bookingDetails.orderId}</div>
              <div className="font-medium">Total Amount Paid:</div>
              <div>{bookingDetails.totalAmount}</div>
              {bookingDetails.discount > 0 && (
                <>
                  <div className="font-medium">Discount:</div>
                  <div>{bookingDetails.discount}</div>
                </>
              )}
              <div className="font-medium">Balance:</div>
              <div>{bookingDetails.balance}</div>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">Courses:</h2>
          <div className="mt-4 bg-gray-50 rounded-lg p-4 border border-gray-200">
            <table className="w-full text-gray-700">
              <thead>
                <tr>
                  <th className="text-left font-medium p-2">Course Name</th>
                  <th className="text-left font-medium p-2">
                    Batch Start Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {bookingDetails.orderDetails.map((course, index) => {
                  if (course.course_type === "PACKAGE") {
                    return Object.entries(course.start_date).map(
                      ([subCourseName, startDate], subIndex) => (
                        <tr
                          key={`${index}-${subIndex}`}
                          className={`border-t ${
                            (index + subIndex) % 2 === 0
                              ? "bg-white"
                              : "bg-gray-50"
                          }`}
                        >
                          <td className="p-2">
                            {subCourseName} (package course)
                          </td>
                          <td className="p-2">{startDate}</td>
                        </tr>
                      )
                    );
                  } else {
                    return (
                      <tr
                        key={index}
                        className={`border-t ${
                          index % 2 === 0 ? "bg-white" : "bg-gray-50"
                        }`}
                      >
                        <td className="p-2">{course.course_name}</td>
                        <td className="p-2">{course.start_date}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-6">
          <p className="text-gray-700">
            Ensure you upload the required documents for verification. If you're
            unable to upload, email them to{" "}
            <a
              href="mailto:admissions@eurotechmaritime.org"
              className="text-blue-600 underline"
            >
              admissions@eurotechmaritime.org
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
