import React, { useEffect, useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { routes } from "../constants/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Endpoints } from "../constants/Endpoints";
import { toast } from "react-toastify";

const BookingFailed = () => {
  const [searchParams] = useSearchParams();
  const order_id = searchParams.get("id");

  const navigate = useNavigate();

  const [bookingDetails, setBookingDetails] = useState({
    orderId: "unknown",
    totalAmount: "0",
    discount: "0",
    balance: "0",
    orderDetails: [],
  });

  const fetchBookingDetails = async (order_id) => {
    try {
      const response = await axios.get(
        `${Endpoints.API_URL}/orders/success-order?order_id=${order_id}`
      );
      if (response.status === 200) {
        const data = response.data;
        setBookingDetails({
          orderId: data.order.order_id,
          totalAmount: data.order.order_total,
          discount: data.order.discounted_amount,
          balance: data.order.pending_fees,
          orderDetails: data.orderDetails,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Can't able to fetch Order details");
    }
  };


  useEffect(() => {
    fetchBookingDetails(order_id);
  }, [order_id]);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <div className="max-w-4xl w-full bg-white rounded-lg shadow-lg p-6">
        <div className="text-center">
          <FaExclamationTriangle className="text-red-500 text-6xl mx-auto" />
          <h1 className="text-2xl font-bold text-red-600 mt-4">
            Booking Failed
          </h1>
          <p className="mt-2 text-gray-700">
            Unfortunately, we couldn't process your booking.
          </p>
        </div>

        <div className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Booking Information:
          </h2>
          <div className="mt-4 bg-gray-50 rounded-lg p-4 border border-gray-200">
            <div className="grid grid-cols-2 gap-4 text-gray-700">
              <div className="font-medium">Order ID:</div>
              <div>{bookingDetails.orderId}</div>
              <div className="font-medium">Total Amount:</div>
              <div>{bookingDetails.totalAmount}</div>
              <div className="font-medium">Balance:</div>
              <div>{bookingDetails.balance}</div>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">
            What You Can Do:
          </h2>
          <ul className="mt-4 space-y-2 text-gray-700">
            <li>
              • Go to your{" "}
              <a
                href={`${routes.COURSE_BOOKING}${routes.COURSE_DETAILS}`}
                className="text-blue-600 underline"
              >
                Cart
              </a>{" "}
              to view your pending booking.
            </li>
            <li>
              • Click on the{" "}
              <button className="text-blue-600 underline">Retry Payment</button>{" "}
              button to complete your booking.
            </li>
            <li>
              • If the issue persists, contact us at{" "}
              <a
                href="mailto:admissions@eurotechmaritime.org"
                className="text-blue-600 underline"
              >
                admissions@eurotechmaritime.org
              </a>
              .
            </li>
          </ul>
        </div>

        {/* Retry Button */}
        <div className="mt-6 flex justify-center">
          <button
            className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-700 transition"
            onClick={() =>
              navigate(`${routes.COURSE_BOOKING}${routes.COURSE_TERMS}`)
            }
          >
            Retry Payment
          </button>
        </div>

        {/* Apology */}
        <div className="mt-6 text-center text-gray-600">
          <p>
            We apologize for the inconvenience and appreciate your patience.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BookingFailed;
