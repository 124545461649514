import React, { useEffect, useRef, useState } from "react";
import PageBanner from "../Components/PageBanner";
import { FiArrowLeft } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import BlueButton from "../Components/BlueButton";
import axios from "axios";
import { Endpoints } from "../constants/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { updateCartCount } from "../redux/ActionCreator";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { load } from "@cashfreepayments/cashfree-js";

const CourseTerms = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const profileDetails = useSelector(({ app }) => app.profileDetails);
  const [cartData, setCartData] = useState([]);
  const [tnc, setTnc] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const dispatch = useDispatch();
  const loading = useRef(null);
  const [isPopUp, setIsPopUp] = useState(false);
  const popUp = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${Endpoints.CMS_URL}/tnc`);
        setTnc(res.data.data[0]);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${Endpoints.BASE_URL}/cart/get-cart`, {
          headers: {
            Authorization: `Bearer ${profileDetails.token}`,
          },
        });
        setTotalAmount(res.data.data.totalAmount);
        setCartData(res.data.data.cart);
        dispatch(updateCartCount(res.data.data.cart.length));
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const handlePopUp = (e) => {
    e.preventDefault();
    setIsPopUp(true);
    popUp.current?.scrollIntoView({
      behaviour: "smooth",
    });
  };

  const initiatePayment = async () => {
    setIsPopUp(false);
    loading.current?.scrollIntoView({ behavior: "smooth" });
    setIsPaymentProcessing(true);

    if (cartData.length === 0) {
      setIsPaymentProcessing(false);
      toast.error("Add at least one course to cart");
      return;
    }

    if (!profileDetails.token) {
      navigate("/login");
      return;
    }

    const params = {
      amount: JSON.stringify(totalAmount),
      id: profileDetails.id,
      customer_name: profileDetails.full_name,
      customer_mobile: profileDetails.phone,
      customer_email: profileDetails.email,
      currency: "INR",
      sms_notify: false,
      email_notify: true,
      partial_payment: true,
      additional_field1: "",
      additional_field2: "",
      redirect_url: `${Endpoints.SITE_URL}/my-courses`,
    };

    try {
      const res = await axios.post(
        `${Endpoints.API_URL}hylo/payment-page`,
        params,
        {
          headers: {
            Authorization: `Bearer ${profileDetails.token}`,
          },
        }
      );

      setIsPaymentProcessing(false);

      if (res.data.status) {
        const cashFreeSession = res.data.data;

        const cashfree = await load({ mode: "production" });
        cashfree.checkout({
          paymentSessionId: cashFreeSession,
          redirectTarget: "_self",
        });
      } else {
        console.log(res.data);
      }
    } catch (error) {
      setIsPaymentProcessing(false);
      console.error("Payment initiation error:", error);
      toast.error("Error initiating payment. Please try again.");
    }
  };

  const updateOrder = async () => {
    try {
      await axios.get(`${Endpoints.API_URL}hylo/order-status`, {
        headers: {
          Authorization: `Bearer ${profileDetails.token}`,
        },
      });
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  useEffect(() => {
    const handleFocus = () => {
      if (location.pathname === "/course-booking/terms") {
        updateOrder();
      }
    };
    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [location.pathname]);

  return (
    <LoadingOverlay
      active={isPaymentProcessing}
      spinner
      text="Please wait... Processing"
    >
      <div>
        <div className=""></div>
        <PageBanner imgUrl="/assets/Governing-board-banner.png" />
        <section className="container max-sm:px-4 mx-auto">
          <div>
            <h1 className="flex my-20 font-semibold max-sm:text-[20px] text-gray-500 uppercase mb-4 items-center text-[24px]  gap-4 ">
              <FiArrowLeft onClick={() => navigate(-1)} /> Terms & Conditions
            </h1>
          </div>
          <div className="border rounded-lg border-gray-300 ">
            <div
              className="content p-2"
              dangerouslySetInnerHTML={{ __html: tnc?.tnc }}
            ></div>

            <form onSubmit={handlePopUp}>
              <div className="flex justify-end">
                <div className="flex justify-end flex-col gap-6 p-6  font-semibold">
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      className="h-[20px] w-[20px]"
                      required
                    />
                    <span>Yes,I am eligible for this course .</span>
                  </div>
                  <div className="flex items-center gap-3  font-semibold">
                    <input
                      type="checkbox"
                      className="h-[20px] w-[20px]"
                      required
                    />
                    <span>I have read & agree to the terms of service.</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="flex justify-end gap-10 max-sm:flex-col max-sm:text-[12px] my-14 mb-20">
            <BlueButton onClick={handlePopUp}>Proceed to Payment</BlueButton>
          </div>
        </section>
        {/* alert pop-up */}
        <div
          className={`${
            isPopUp ? "flex" : "hidden"
          } fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center`}
        >
          <div className="bg-white shadow-lg rounded-lg w-full max-w-3xl">
            <div className="p-6 flex flex-col md:flex-row items-center gap-6">
              <div className="flex-shrink-0">
                <img
                  src="/assets/payment-alert.png"
                  alt="Payment Alert Icon"
                  className="w-32 h-32"
                />
              </div>

              {/* Instructions */}
              <div className="flex-1 text-center md:text-left">
                <p className="text-2xl font-semibold text-gray-800">
                  Ready to Make Your Payment
                </p>
                <p className="text-gray-700 mt-2">
                  You will now be redirected to our secure payment page.
                </p>
                <ul className="list-disc list-inside text-left mt-4 text-gray-600">
                  <li>
                    Do not press the <span className="text-red-600">Back</span>{" "}
                    button.
                  </li>
                  <li>
                    Avoid refreshing the{" "}
                    <span className="text-red-600">page</span>.
                  </li>
                  <li>
                    Do not close the{" "}
                    <span className="text-red-600">browser</span> or{" "}
                    <span className="text-red-600">tab</span>.
                  </li>
                </ul>
                <p className="mt-4 text-gray-700">
                  Once the payment is successful, you will automatically be
                  redirected back to our website.
                </p>
              </div>
            </div>

            {/* Footer Section */}
            <div className="bg-gray-100 px-6 py-4 flex justify-end gap-4 rounded-b-lg">
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-700 py-2 px-6 rounded-md font-bold transition"
                onClick={() => setIsPopUp(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-6 rounded-md font-bold transition"
                onClick={initiatePayment}
              >
                Proceed to Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default CourseTerms;
